body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  overflow: hidden;
  height: 100vh;
  width: 100vw;
}

.App {
  height: 100vh;
  width: 100vw;
  overflow: hidden;
}

html {
  overflow: hidden;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.esri-attribution {
  display: none !important;
}

.esri-ui-top-left {
  top: unset !important;
  left: unset !important;
  bottom: -10px !important;
  right: 80px !important;
}

* {
  -webkit-tap-highlight-color: transparent;
}

*::-webkit-scrollbar {
		width: 5px !important;
	}

*::-webkit-scrollbar-thumb {
	  border-radius: 10px;
	  -webkit-box-shadow: inset 0 0 5px rgba(56, 125, 214, 0.3);
	  background-color: rgb(101, 112, 158);
      cursor: pointer;
}
